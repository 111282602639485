@import '~bootstrap/dist/css/bootstrap-reboot.min.css';
@import './settings.scss';

.app {
}

.max-width-container {
	max-width: $max-width-mobile;
	margin: auto;
	padding: 0 $product-margins;
	&.no-padding {
		padding: 0;
	}
}

.pincode-input-container {
	text-align: center;
	margin-bottom: 10px;
}

.edit,.auth {
	> h2, > p {
		text-align: center;
		margin-bottom: 10px;
		padding: 0 $product-margins;
	}
	> h2 {
		margin-top: 20px;
		&:first-child{
			margin-top: 0;
		}
	}
	padding-top: $product-margins;
}



.pincode-input-container .pincode-input-text {
	border: 1px solid #ced4da !important;
	border-radius: 4px !important;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
	background-color: #fff !important;

	&:focus {
		border-color: #80bdff !important;
		box-shadow: 0 0 0 3px rgba(0,123,255,.25) !important;
	}
}
.max-width-container input,.max-width-container textarea {
	width: 100%;
	font-size: 20px;
	display: block;
	margin: 10px 0;
	padding: 5px;
	border-radius: 4px;
	border: 1px solid #ced4da;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	&:focus {
		border-color: #80bdff;
		outline: 0;
		box-shadow: 0 0 0 3px rgba(0,123,255,.25);
	}
}
.max-width-container textarea {
	height: 100px;
}
.photo-mode input {
	display: none;
}
.share-block {
	height: 100px;
	.inner {
		height: 100px;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #fff;
		background: rgba(255,255,255,0.96);
		backdrop-filter: blur(4px);
		z-index: 2;
		border-top: 1px solid $borders-color;
	}
}

span.nowrap {
	white-space: nowrap;
}

@import './settings.scss';



.component-btn {
	user-select: none;
	max-width: 1000px;
	margin: auto;
	height: 100px;
	position: relative;
	cursor: pointer;
	.left,.right {
		position: absolute;
		top: 0;
		height: 100px;
		background-image: url('./imgs/btn.svg');
		background-repeat: no-repeat;
	}
	p {
		flex-grow: 1;
		margin: 0;
		line-height: 20px;
		color: #000;
		font-size: 16px;
		text-align: center;
		font-weight: 500;
		text-shadow: 0 1px 0 rgba(255,255,255,0.50);
	}
	.left {
		display : flex;
		align-items : center;
		overflow: hidden;
		left: 0;
		right: 100px;
		padding-left: 26px;
		background-position: 0% -200px;
	}
	.right {
		width: 100px;
		right: 0;
		background-position: 100% -200px;
	}
	&:hover {
		.left {
			background-position: 0% -300px;
		}
		.right {
			background-position: 100% -300px;
		}
	}

	&.primary {
		p {
			color: #fff;
			text-shadow: 0 1px 0 rgba(0,0,0,0.50);
		}
		.left {
			background-position: 0% 0px;
		}
		.right {
			background-position: 100% 0px;
		}
	}
	&.primary:hover {
		.left {
			background-position: 0% -100px;
		}
		.right {
			background-position: 100% -100px;
		}
	}
	&.red {
		p {
			color: #fff;
			text-shadow: 0 1px 0 rgba(0,0,0,0.50);
		}
		.left {
			background-position: 0% -400px;
		}
		.right {
			background-position: 100% -400px;
		}
	}
	&.red:hover {
		.left {
			background-position: 0% -500px;
		}
		.right {
			background-position: 100% -500px;
		}
	}
	span {
		white-space: nowrap;
	}
}

.component-header {
	h1,p {
		margin-bottom: 0;
	}
	.scroller {
		border-bottom: 1px solid $borders-color;
		height: $header-height;
		z-index: 2;
		background-color: #fff;
		background: rgba(255,255,255,0.96);
		backdrop-filter: blur(4px);
	}
	.content {
		max-width: $max-width-mobile;
		margin: auto;
		display: flex;
		padding: 0 $product-margins;
		align-items: center;
		height: $header-height - 1px;
		justify-content: space-between;
		overflow: hidden;
	}



	h1,a {
		display: inline-block;

	}

	h1 {
		font-size: 21px;
		line-height: 1;
		&.large {
			@media (min-width: 375px){
				font-size: 29px;
			}
		}
	}
	a {

		background: #008FE1;
		border: 1px solid rgba(0,0,0,0.20);
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
		border-radius: 4px;
		height: 36px;
		line-height: 35px;
		padding: 0 6px;
		white-space: nowrap;

		color: #fff;
		cursor: default;
		text-decoration: none;
		font-size: 16px;
		font-weight: 500;

		word-spacing: -1px;
		letter-spacing: -0.5px;

		&:active, &:hover {
			color: #fff;
			text-decoration: none;
		}

	}
	&.fixed {
		.scroller {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
		}
		.filler {
			height: $header-height;
		}
	}
}

.component-footer {
	border-top: 1px solid $borders-color;
	&.no-border {
		border-top: none;
	}
	.content {
		max-width: $max-width-mobile;
		margin: auto;
		padding: $product-margins;
	}
	div.img {
		float: right;
		border: 1px solid rgba(0,0,0,0.15);
		//box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
		border-radius: 4px;
		width: 150px;
		height: 150px;
		background-size: cover;
		background-origin: border-box;
		background-position: center;
		background-repeat: no-repeat;
	}
	p {
		margin-bottom: 10px;
	}
	p.notes {
		border: 1px solid $borders-color;
		border-radius: 4px;
		padding: $product-margins;
	}
	a {
		text-decoration: underline;
		font-weight: 700;
	}
	h2 {
		margin-bottom: 0;
	}
	.uppercase {
		text-transform: uppercase;
	}
}


// .component-title {
// 	h2 {
// 		margin-bottom: 0;
// 		padding: 0 $product-margins;
// 	}
// }


.component-space {
	height: $product-margins;
}
@media only screen and (min-width: 600px){
.component-space {
	height: 24px;
}
}

$touchable-height: 64px;
$line-circle-diameter: 44px;
.reorder-components-list {
	margin: 20px 0;
	.line {
		&.ghost {
			opacity: 0.4;
		}
		height: $touchable-height;
		border: 1px solid #ddd;
		margin-bottom: -1px;
		position: relative;
		background-color: #fff;
		.text {
			padding-left: $touchable-height;
			padding-right: $touchable-height;
			text-overflow: ellipsis;
			overflow: hidden;
			line-height: $touchable-height;
			white-space: nowrap;
		}
		.img {
			width: $line-circle-diameter;
			height: $line-circle-diameter;
			border-radius: 3px;
			left: ($touchable-height - $line-circle-diameter)/2;
			top: ($touchable-height - $line-circle-diameter)/2;
			position: absolute;

			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}
		.handle {
			background-image: url('./imgs/move.svg');
			background-position: center;
			background-repeat: no-repeat;
			height: $touchable-height;
			width: $touchable-height;
			right: 0;
			top: 0;
			position: absolute;
			cursor: move;
			cursor: grab;
		}
	}
}

.component-product {
	height: auto;
	background-color: #eee;

	// @media (min-width: $max-width-mobile){
	// }
	border: 1px solid rgba(0,0,0,0.20);
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
	border-radius: 4px;
	position: relative;

	&.img {
		height: 0;
		padding-top: 100%;
		background-size: cover;
		background-origin: border-box;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;
		.line-top,.line-bottom {
			position: absolute;
		}
	}

	.btn-container {
		position: absolute;
		background-color: rgba(255,255,255,0.6);
		border-radius: 4px;
		bottom: -1px;
		right: -1px;
		left: -1px;
		top: -1px;
		border: 3px solid #028ED3;
		display: flex;
		justify-content:center;
		flex-direction: column;
		.component-btn {
			//flex-grow: 1;
			width: 100%;
			margin: 0;
		}
		&.muted {
			border: none;
		}
	}

	.sold-block {

		position: absolute;
		bottom: -3px;
		right: -3px;
		height: 104px;
		width: 104px;
		background-image: url('./imgs/sold.png');
		background-repeat: no-repeat;
	}


	.line-top,.line-bottom {
		padding: $product-margins;
		left:0;
		right:0;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	.line-top {
		top:0;
		padding-bottom: $product-margins + 5px;
	}
	.line-bottom {
		bottom:0;
		padding-top: 0;
		padding-bottom: $product-margins+2px;


		a {
			text-decoration: underline;
			font-weight: 700;
		}
	}


	&.masked {
		.btn-container,.line-bottom {
			bottom: 29px;
		}
		.masked-message {
			background-color: #333;
			border-radius: 0 0 4px 4px;
			bottom: -1px;
			right: -1px;
			left: -1px;
			height: 33px;
			line-height: 33px;
			padding: 4px;

			position: absolute;



			line-height: 20px;
			font-size: 16px;
			text-align: center;
			font-weight: 500;

			color: #fff;
			text-shadow: 0 1px 0 rgba(0,0,0,0.50);
		}
	}

	.line-top,.line-bottom {

		h3,p {
			margin: 0;
		}
		h3,p {
			border: 1px solid #979797;
			box-shadow: 4px 5px 0 0 rgba(0,0,0,0.20);
			padding: 4px 8px;
		}
		h3,p.desc {
			background-color: #fff;
		}

		h3 {
			margin-right: $product-margins;
			text-wrap: balance;
		}

		p.price {
			background: #F8E71C;
			font-weight: 900;
			font-size: 28px;
			color: #E00017;
			text-shadow: 1px 1px 0 #FFFFFF;
			padding: 1px 8px;
		}

		p.desc {
		}
	}
}
